<template>
    <div>
        <v-dialog v-model="dialog" @input="dialogChanged" max-width="600">
    <template v-slot:activator="{ on, attrs }">
        <v-chip :color="languageHasValue(language.value)?'success':''" class="mr-2" v-for="language in translation_languages" :key="language.value" small v-bind="attrs" v-on="on">{{language.value}}</v-chip>
    </template>

      <v-card :loading="loading">
        <v-card-title class="mb-4">
            <v-avatar color="primary" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              mdi-translate
            </v-icon>
          </v-avatar>
          <span class="headline">Vertalingen</span>
        </v-card-title>

        <v-card-text v-if="translation_item && !loading">
            <v-tabs v-model="tab" background-color="transparent" color="primary" grow>
                <v-tab v-for="language in translation_languages" :key="language.value">
                    {{ language.text }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item v-for="language in translation_languages" :key="language.value">
                    <v-card flat>
                        <v-card-text>
                            <tiptap-vuetify v-if="wysiwyg" v-model="translation_item.fields[getValueFieldIndex()].translations[getTranslationIndex(language.value)].value" :extensions="extensions" placeholder="Typ hier uw tekst..." />
                            <v-textarea v-if="!wysiwyg" outlined hide-details v-model="translation_item.fields[getValueFieldIndex()].translations[getTranslationIndex(language.value)].value"></v-textarea>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="save"
          >
            Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>
<script>
    import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, History, HardBreak, Table, TableCell, TableHeader, TableRow } from 'tiptap-vuetify';

    export default {
        name: 'eod-translations',
        components:{
            'tiptap-vuetify': TiptapVuetify,
        },
        props: {
            value: String,
            autoTranslate: {
                type: Boolean,
                default: false
            },
            wysiwyg: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            languages: [],
            translation_item: null,
            dialog: false,
            tab:null,
            loading: false,
            extensions: [
                History,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                    levels: [1, 2, 3]
                    }
                }],
                Bold,
                Paragraph,
                HardBreak,
                Table,
                TableCell,
                TableHeader,
                TableRow
            ],
        }),
        created(){
            this.languages = this.$eod.getLanguages();
        },
        computed:{
            translation_languages(){
                let languages = this.languages.filter(language => {
                    return !language.isDefault;
                });

                languages.sort((a, b) => (a.value > b.value) ? 1 : -1);

                return languages;
            }
        },
        methods:{
            async dialogChanged(val){
                if(val){
                    this.loading = true;
                    await this.getTranslationsByKey(this.value);
                    this.loading = false;
                }
            },
            save(){
                this.translation_item.listId = this.$eod.getOrganizationSetting('translations.listId').value;
                this.$eod.save('ListItem', this.translation_item)
                    .then(result => {
                        this.dialog = false;
                    })
                    .catch(err => {
                        console.log(err.message);
                    });
            },
            languageHasValue(locale){

                if(this.autoTranslate){
                    return true;
                }

                if (this.translation_item) {
                    let fieldIndex = this.getValueFieldIndex();
                    if(this.translation_item.fields[fieldIndex]){
                        for (let i = 0; i < this.translation_item.fields[fieldIndex].translations.length; i++) {
                            const translation = this.translation_item.fields[fieldIndex].translations[i];
                            if (translation.locale == locale) {
                                return translation.value && translation.value != '';
                            }
                        }
                    }
                }

                return false;
            },
            getValueFieldIndex(){
                if (this.translation_item) {
                    for (let i = 0; i < this.translation_item.fields.length; i++) {
                        const field = this.translation_item.fields[i];
                        if (field.name == 'value') {
                            return i;
                        }
                    }
                }

                return null;
            },
            getTranslationIndex(locale){
                if (this.translation_item) {
                    let fieldIndex = this.getValueFieldIndex();
                    for (let i = 0; i < this.translation_item.fields[fieldIndex].translations.length; i++) {
                        const translation = this.translation_item.fields[fieldIndex].translations[i];
                        if (translation.locale == locale) {
                            return i;
                        }
                    }
                }

                return null;
            },
            // Made for bugfix translations can't be null
            cleanFields(){
                if (this.translation_item) {
                    for (let i = 0; i < this.translation_item.fields.length; i++) {
                        const field = this.translation_item.fields[i];
                        if (field.name == '' || field.translations == null) {
                            this.translation_item.fields.splice(i, 1);
                        }
                    }
                }

                // Fix
                if(this.getValueFieldIndex() == null){
                    this.translation_item.fields.push({
                        name: 'value',
                        translations: []
                    });
                }
            },
            getTranslationsByKey(key){
                return this.$eod.get('listItems', ['id', 'name', 'fields{name translations{locale value}}'], {
                    where:[
                        {
                            column: 'listId',
                            value: this.$eod.getOrganizationSetting('translations.listId').value,
                            operator: '=',
                            chain: 'and'
                        },
                        {
                            column: 'name',
                            value: key,
                            operator: '=',
                            chain: 'and'
                        }
                    ]
                })
                .then(async result => {
                    if (result.data.data.listItems.edges[0]) {
                        this.translation_item = result.data.data.listItems.edges[0];

                        this.cleanFields();

                        for (let i = 0; i < this.translation_languages.length; i++) {
                            const lang = this.translation_languages[i];
                            if (this.getTranslationIndex(lang.value) == null) {
                                this.translation_item.fields[this.getValueFieldIndex()].translations.push({
                                    locale: lang.value,
                                    value: '',
                                    isActive: true
                                })
                            }
                        }
                    }else{
                        this.translation_item = {
                            name: this.value,
                            fields: [
                                {
                                    name: 'value',
                                    translations: []
                                }
                            ]
                        };

                        for (let i = 0; i < this.translation_languages.length; i++) {
                            const lang = this.translation_languages[i];

                            let translated = '';
                            if(this.autoTranslate){
                                translated = await this.$translator.getTranslationByLocale(key, lang.value);
                            }

                            this.translation_item.fields[0].translations.push({
                                locale: lang.value,
                                value: translated,
                                isActive: true
                            })
                        }
                    }
                });
            }
        }
    }
</script>