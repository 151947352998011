class Translator {
    translationsListId = null;
    translations = null;
    eod = null;
    locale = 'nl';
    languages = null;
    defaultLanguage = null;

    constructor(eod){
        this.eod = eod;
        const translationListSetting = eod.getOrganizationSetting('translations.listId');
        if(translationListSetting && translationListSetting.value && translationListSetting.value != ''){
            this.translationsListId = translationListSetting.value;
        }

        this.languages = eod.getLanguages();
        this.defaultLanguage = eod.getDefaultLanguage().value;
    }

    setLocale(locale){
        this.locale = locale;
    }

    async loadTranslations(){
        if(this.translations){
            return false;
        }

        if(this.translationsListId){
            const listItems = await this.eod.get('listItems', ['id', 'name', 'fields{name translations{locale value}}'], {
                where: [
                    {
                        column: 'listId',
                        value: this.translationsListId,
                        operator: '='
                    }
                ]
            }).then(response => {
                if(response.data.data && response.data.data.listItems && response.data.data.listItems.edges){
                    return response.data.data.listItems.edges;
                }

                return [];
            }).catch(() => {
                return [];
            });

            this.translations = {};

            for (let i = 0; i < listItems.length; i++) {
                const listItem = listItems[i];
                this.translations[listItem.name] = {};
                if(listItem.fields){
                    for (let j = 0; j < listItem.fields.length; j++) {
                        const field = listItem.fields[j];
                        if(field.name = 'value'){
                            if(field.translations){
                                for (let t = 0; t < field.translations.length; t++) {
                                    const translation = field.translations[t];
                                    this.translations[listItem.name][translation.locale] = translation.value;
                                }
                            }
                            break;
                        }
                    }
                }
            }
        }
    }

    async getTranslationByLocale(key, locale){
        return this.eod.getTranslation(key, locale).then(response => {
            if(response.data && response.data.data && response.data.data.translation){
                return response.data.data.translation.text;
            }

            return key;
        });
    }

    async getTranslation(key){

        if(this.locale == this.defaultLanguage){
            return key;
        }

        if(this.translations && this.translations[key] && this.translations[key][this.locale]){
            return this.translations[key][this.locale];
        }

        if(!this.translations[key]){
            this.translations[key] = {};
        }

        this.translations[key][this.locale] = await this.eod.getTranslation(key, this.locale).then(response => {
            if(response.data && response.data.data && response.data.data.translation){
                return response.data.data.translation.text;
            }

            return key;
        }).catch(() => {
            return key;
        });

        return this.translations[key][this.locale];
    }
}

export default Translator;