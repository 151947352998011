const {default: Project} = require('./../../eodvuecomponents/src/models/project');

module.exports = {
    routes: [{
        path: '/projects',
        name: 'projects',
        meta:{
            permissions:['projects.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/projects/detail/:id?',
        name: 'project',
        meta:{
            permissions:['projects.create', 'projects.update']
        },
        component: () => import('./views/detail.vue')
    }],
    search: Project.searchConfig,
    itemObject: Project,
    detail: {
        route: 'project',
        endpoint: 'project',
        objectName: 'Project',
        fields: ['id', 'name', 'description', 'storageObjects{id}', 'availability{startDateTime endDateTime endTimeUnspecified recurrence}', 'tags{id name color}', 'locations{name description streetAddress postalCode locality region country notes lat lon}', 'company{id}', 'users{id, firstName, lastName, username, email, assignedRealmRoles{id name}, attributes{phone_number, jobtitle nickname birthdate gender locality locale iban bic postal_code street_address region country notes extra_email extra_phone}}', 'groups{id name}', 'templates{id name type default projects{id name} companies{id name}}'],
        tabs: [{
            tab: 'general',
            text: 'Algemeen'
        },
        {
            tab: 'products',
            text: 'Toestellen',
            counter: (item, eod) => {
                if(!item || !item.id){
                    return 0;
                }

                return eod.get('treeObjects', ['id', 'childrenCount'], {
                    parentId: item.id,
                    whereIn:[
                        {
                            column: 'tree_objects_view.class',
                            array: ["COMPANY", "PROJECT", "DEVICE", "DEVICE_FOLDER"]
                        }
                    ]
                }).then(response => {
                    if (response.data.data && response.data.data.treeObjects.edges) {
                        return response.data.data.treeObjects.edges.length;
                    } else {
                        return 0;
                    }
                });
            }
        },
        {
            tab: 'storageObjects',
            text: 'Media',
            counter: 'storageObjects'
        },
        {
            tab: 'groups',
            text: 'Teams',
            counter: 'groups',
            visible(item, eod){
                return eod.getUser().can('routes.create');
            }
        },
        {
            tab: 'availability',
            text: 'Beschikbaarheid',
            visible(item, eod){
                return true;
                //return eod.getUser().can('routes.create');
            }
        },
    ],
    },
    index: {
        route: 'projects',
        endpoint: 'projects',
        fields: ['id', 'name', 'company{id name}', 'isActive', 'description', 'tags{id name color}', 'createdAt', 'updatedAt'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: 'Omschrijving',
                value: 'description',
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: 'Bedrijf',
                value: 'company',
                sortable: false,
                component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-company.vue'),
                extra: {
                    icon: 'mdi-office-building-outline',
                    collapsed: false,
                }
            },
            {
                text: 'Tags',
                value: 'tags',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-tags.vue')
            },
            {
                text: 'Actief',
                value: 'isActive',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-toggle.vue')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('./../default/columns/date.vue'),
                extra:{
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                width: 120,
                sortable: false,
                component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-actions.vue')
            }
        ],
        tabs: [{
            path: 'projects',
            text: 'Overzicht'
        }],
    },
    export: {
        endpoint: 'projects',
        fields: ['name', 'description', 'startDate', 'deliveredDate', 'createdAt', 'updatedAt', 'users {username firstName lastName email attributes {locale}}', 'company{name}']
    },
    import: {
        endpoint: 'Projects',
        objectName: 'Project',
    },
}